import apiEndpoints from '@/models/common/api-endpoints';
import clinicSelector from '@/components/ClinicSelector';
import generalFunctions from '@/models/common/general-functions';
import jwt from 'jsonwebtoken';
import { mapGetters } from 'vuex';

export default {
  name: 'admin-devices',
  components: {
    clinicSelector
  },
  data () {
    return {
      expansion: undefined,
      magicGUID: '00000000-0000-0000-0000-000000000000',
      cardTitle: 'Devices',
      editTitle: 'Edit Device',
      newTitle: 'New Device',
      infoTitle: 'Device Info',
      createNewBtnTitle: 'Add Device',
      infoDialog: false,
      editDialog: false,
      rawConnectors: [],
      newObjectValid: false,
      editObjectValid: false,
      newDialog: false,
      newObject: {
        name: undefined,
        type: 'WitnessDB'
      },
      defaultNewObject: {
        name: undefined,
        type: 'WitnessDB'
      },
      // deviceTypes: ['WitnessDB', 'G210'],
      deviceTypes: ['WitnessDB'],
      card_title: 'Devices',
      timer: '',
      mainTimer: '',
      emailAlerts: undefined,
      emailAlertsOriginal: undefined,
      emailAlertsLoading: true,
      editedItem: {},
      originalItem: {},
      originalConfigObject: {},
      infoItem: {},
      editedIndex: undefined,
      infoIndex: undefined,
      search: undefined,
      loadingTable: true,
      headers: [
        {
          text: 'Device Name/ID',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Device Type',
          align: 'start',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      items: undefined,
      editProcessing: false,
      newProcessing: false,
      availablePermissions: [
        'GlobalAdmin'
      ],
      rules: {
        required: value => !!value || 'A value is required',
        requireguid: value => {
          if (!value || value === this.magicGUID) {
            return 'Clinic connector is required';
          }
          return !!value;
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid email address entered';
        },
        minLength: value => {
          return (value || '').length > 2 || 'Clinic name must contain at least 3 characters';
        },
        validIPv4: value => {
          // credit for regex: Danail Gabenski
          // https://stackoverflow.com/questions/5284147/validating-ipv4-addresses-with-regexp
          const pattern = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
          return pattern.test(value) || 'Invalid IPv4 address entered';
        },
        validPort: value => {
          return (Number(value)>0 && Number(value)<65536) ? true : 'Port number must be an integer between 0 and 65535';
        },
        oneWitnessDBAllowed: value => {
          if (value == 'WitnessDB') {
            let witnessDBs = this.items.filter(device => device.type == 'WitnessDB');
            if (witnessDBs.length > 0) {
              return 'Only one WitnessDB device is allowed per clinic';
            } else {
              return true;
            }
          }
        }
      }
    };
  },
  methods: {
    showInfo: async function (item) {
      if (item.status && item.status === 'Pending') {
        this.newClinicInstallProgress(item);
        return;
      }
      this.infoIndex = this.items.indexOf(item);
      this.infoItem = Object.assign({}, item);

      this.expansion = false;

      this.timer = setInterval(this.updateInfo, 5000);

      if (this.infoItem.status === 'Error') {
        await this.getInfoItemErrors();
      }

      this.infoDialog = true;
    },
    updateInfo: async function () {
      const response = await apiEndpoints.getDevicesForCurrentClinic();

      for (let device of response.data) {
        if (device.deviceId === this.infoItem.deviceId) {
          for (const key in device) {
            if (this.infoItem[key]) { this.infoItem[key] = device[key]; }
          }
          if (this.infoItem.status === 'Error') {
            this.getInfoItemErrors();
          }
        }
      }
    },
    getInfoItemErrors: async function () {
      const response = await apiEndpoints.getDeviceErrors(this.infoItem.deviceId);

      if (response.result) {
        this.infoItem.errorTime = response.data[0].receiveddate;
        this.infoItem.errorMessage = response.data[0].error;
        this.infoItem.errorName = response.data[0].name;
        this.infoItem.errorString = response.data[0].errorstring;
      }
    },
    closeInfo () {
      clearInterval(this.timer);

      this.infoDialog = false;
    },
    initNew () {
      this.newObject = this.defaultNewObject;

      this.showNew(this.newObject);
    },
    showNew: async function (item) {
      this.newDialog = true;
      await this.$nextTick();
      this.$refs.newDeviceForm.validate();
    },
    newClose () {
      this.newDialog = false;
    },
    newCreate: async function () {
      this.newProcessing = true;

      const response = await apiEndpoints.createDevice(this.newObject.name, this.newObject.type, this.currentClinic.clinicId);

      if (response.result) {
        await this.retrieveData();
  
        this.editItem(response.data);
  
        this.newDialog = false;
  
        this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      } else {
        this.newDialog = false;
      }
      
      this.newProcessing = false;
    },
    editItem: async function (item) {
      const response = await apiEndpoints.getDeviceConfig(item.deviceId, this.currentClinic.clinicId);

      if (response.result) {
        this.originalConfigObject = generalFunctions.deepCopy(response.data);
  
        const completedObject = { ...item, ...response.data };
  
        this.originalItem = generalFunctions.deepCopy(completedObject);
        this.editedItem = generalFunctions.deepCopy(completedObject);
  
        this.editDialog = true;
  
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    editClose () {
      this.editDialog = false;
    },
    editSave: async function () {
      this.editProcessing = true;

      if (this.nameEdited) {
        await apiEndpoints.renameDevice(this.editedItem.deviceId, this.editedItem.name, this.currentClinic.clinicId);
      }

      if (this.configEdited) {
        const configReturn = {};
        for (let key in this.originalConfigObject) {
          configReturn[key] = this.editedItem[key];
        }

        const response = await apiEndpoints.updateDeviceConfig(this.editedItem.deviceId, configReturn, this.currentClinic.clinicId);
        
        if (response.result) {
          await this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
        }

      }

      await this.retrieveData();

      this.editDialog = false;

      this.editProcessing = false;
    },
    showEdit (item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editDialog = true;
    },
    retrieveData: async function () {
      this.loadingTable = true;

      const response = await apiEndpoints.getDevicesForCurrentClinic();

      if (response.result) {
        this.items = response.data;
      }

      this.loadingTable = false;
    },
    retrieveConnectors: async function () {
      const response = await apiEndpoints.getConnectorsForCurrentClinic();

      if (response.result) {
        this.rawConnectors = response.data;
      }
    },
    deleteItem: async function (item) {
      if (!confirm('Are you sure you want to delete this device?')) {
        return;
      }

      // Delete the device
      const response = await apiEndpoints.deleteDevice(item.deviceId, this.currentClinic.clinicId);

      if (response.result) {
        // Refresh the table
        this.retrieveData();
  
        // Refresh deviceInfo etc.
        this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      }
    },
    getSubscriptionState: async function () {
      this.emailAlertsLoading = true;
      this.emailAlerts = undefined;
      this.emailAlertsOriginal = undefined;
      let decodedToken = jwt.decode(this.idToken);
      const response = await apiEndpoints.getDeviceAlertSubscriptionState(this.currentClinic.clinicId, decodedToken.sub);
      if (response.result) {
        if (response.data === true) {
          this.emailAlerts = true;
          this.emailAlertsOriginal = true;
          this.emailAlertsLoading = false;
        } else if (response.data === false) {
          this.emailAlerts = false;
          this.emailAlertsOriginal = false;
          this.emailAlertsLoading = false;
        }
      }
    }
  },
  mounted () {
    this.retrieveData();
    this.retrieveConnectors();
    this.getSubscriptionState();
  },
  created () {
    this.mainTimer = setInterval(this.retrieveData, 20000); // update the table every 20 seconds
  },
  beforeDestroy () {
    // make sure we have no timers still running
    clearInterval(this.timer);
    clearInterval(this.mainTimer);
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      idToken: 'idToken'
    }),
    configEdited: function () {
      let edited = false;
      for (let key in this.originalItem) {
        if (key !== 'name' && this.originalItem[key] !== this.editedItem[key]) {
          edited = true;
        }
      }
      return edited;
    },
    nameEdited: function () {
      if (this.originalItem.name !== this.editedItem.name) {
        return true;
      }
      return false;
    },
    emailAlertsChanged: function () {
      if (this.emailAlerts !== undefined && this.emailAlertsOriginal !== undefined && this.emailAlerts !== this.emailAlertsOriginal) {
        return true;
      }
      return false;
    },
    connectors: function () {
      const output = [];
      for (let connector of this.rawConnectors) {
        if (connector.status !== 'Pending') {
          output.push({
            name: connector.name || connector.clinicConnectorId,
            clinicConnectorId: connector.clinicConnectorId
          });
        }
      }
      return output;
    }
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.retrieveData();
      this.retrieveConnectors();
      this.getSubscriptionState();
    },
    // allClinics: function (newVal, oldVal) {
    //   this.retrieveData();
    //   this.retrieveConnectors();
    //   this.getSubscriptionState();
    // },
    emailAlertsChanged: async function (newVal, oldVal) {
      if (newVal === true) {
        let decodedToken = jwt.decode(this.idToken);
        if (this.emailAlerts) {
          const response = await apiEndpoints.updateDeviceAlertSubscription(this.currentClinic.clinicId, decodedToken.sub, true);
          if (response.result) {
            const addSubReturn = response.data;
            if (addSubReturn === true) {
              this.emailAlertsOriginal = this.emailAlerts;
            } else {
              this.emailAlerts = false;
            }
          } else {
            this.emailAlerts = false;
          }
        } else {
          const response = await apiEndpoints.updateDeviceAlertSubscription(this.currentClinic.clinicId, decodedToken.sub, false);
          if (response.result) {
            const removeSubReturn = response.data;
            if (removeSubReturn === false) {
              this.emailAlertsOriginal = this.emailAlerts;
            } else {
              this.emailAlerts = true;
            }
          } else {
            this.emailAlerts = true;
          }
        }
      }
    }
  }
};